
import {defineComponent} from 'vue';
import $ from "jquery";
import commonOnLoad from "@/mixins/commonOnLoad";
import store from "@/store";
import GoogleAnalytics from "@/components/common/googleanalytics.vue";


export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'OtherProjectsView',
    components: {GoogleAnalytics},
    mixins: [commonOnLoad],
    methods: {},
    mounted() {
        this.updateHeightContainer(this.$route.name);
    },
    computed: {
        withTracking() {
            return store.getters.withTracking
        }
    },
    data() {
        return {
            experiences: [
                {
                    "id": "rbc",
                    "title": "Simulateur de Rubik's cube ",
                    "client": "Rubik's cube",
                    "logo": "rubikscube.png",
                    "start": "2022",
                    "end": "",
                    "content":
                        "<li>Application web permettant de simuler un rubik's Cube</li>" +
                        "<li>Développé en utilisant le framework Three.js</li>" +
                        "<li>" +
                        "   <ul>" +
                        "       <li>Possibilité de faire pivoter le cube dans toutes les directions, mouvement de chacune des faces par drag & drop</li>" +
                        "       <li>Mise en place d'un tutoriel avec un guidage pas à pas de chaque étape de resolution du cube</li>" +
                        "       <li>Possibilité de revenir en arrière vers chaque palier de résolution</li>" +
                        "   </ul>" +
                        "</li>",
                    "skills": "Three.js, javascript, Jquery",
                },
                {
                    "id": "ldv",
                    "title": "Site de l'association 'Les Dits de Vergne'",
                    "client": "Les Dits de Vergne",
                    "logo": "ldv.png",
                    "start": "2017",
                    "end": "2019",
                    "content":
                        "<li>J'ai développé ce site sans l'aide d'aucun CMS. J'ai donc construit moi l'interface de l'\"Espace membre\", permettant aux membres de la troupe de gérer le contenu du site.</li>" +
                        "<li><ul><li>Il m'a donc fallu penser toute l'arborescence et le modèle de donnée (bases de données MYSQL) afin de permettre aux membres d'éditer et modifier les différents contenus du site (articles (éditeur WYSIWYG), évènements, fichiers). </li>" +
                        "<li>Un système d’habilitations utilisateur (invité, rédacteur, administrateur) a d'ailleurs été mis en place pour permettre aux personnes suivant le rôle qu'il joue sur le site de pouvoir modifier tel ou tel contenu.</li>" +
                        "<li>J'ai mis en place un gestionnaire de fichier (cloud) avec un affichage de type windows (permettant le clic droit et le glisser déposer de fichiers)</li></ul></li>" +
                        "<li>Coté publique, j'ai mis en place des animations graphiques incluant de la 3D. (Colonne Moris, archives des pièces jouées plan par plan sur la page d'accueil et diaporama de photo avec preview cylindrique). Je tiens à préciser que j'ai fait cela sans l'aide d'aucun framework : J'ai simplement utilisé les fonctions CSS3 ainsi que du javascript.</li>",
                    "skills": "Les technologies utilisées pour ce site sont HTML, CSS, JS, jQuery pour le front, et PHP et MySQL pour le back office.\n" +
                        "Actuellement je travaille à rendre ce site responsive.",

                },
                {
                    "id": "euro",
                    "title": "Site de paris en ligne privé",
                    "client": "Site de paris en ligne privé",
                    "logo": "euro.png",
                    "start": "juin 2016",
                    "end": "juillet 2016",
                    "content":
                        "<li>Développement d'un site privé de paris en ligne à l'occasion de la coupe d'Europe 2016.</li>" +
                        "<li><ul><li>Réalisation des templates</li>" +
                        "<li>Mise en place du modèle de données</li>" +
                        "<li>Dynamisation des contenus. (formulaires de saisie des paris)</li>" +
                        "<li>suivi du classement des participants.</li>",
                    "skills": "HTML, CSS pour le front, PHP et MySQL pour le back",
                },
                {
                    "id": "egvm",
                    "title": "Site internet d'une communauté paroissiale",
                    "client": "Eglise catholique du Val Maubuée",
                    "logo": "egvm.png",
                    "start": "2015",
                    "end": "",
                    "content":
                        "<li>communauté paroissiale catholique du val Maubuée regroupe plusieurs paroisses catholiques et fédère l'organisation des événements de la vie catholique locale.</li>" +
                        "<li>J'ai créé le site https://www.eglise-catholique-val-maubuee.fr/ à l'aide du CMS Joomla auquel j'ai ajouté des modules PHP personnalisés :</li>" +
                        "<li>" +
                        "   <ul>" +
                        "       <li>gestionnaire de fichiers</li>" +
                        "       <li>gestionnaire de la mémoire </li>" +
                        "       <li>indicateurs d'audience </li>" +
                        "       <li>gestionnaire d'évènements </li>" +
                        "   </ul>" +
                        "</li>" +
                        "<li>Ce site a permis une meilleure visibilité pour le bassin de fidèles de la communauté, et a participé à la dynamisation de la vie paroissiale avec une participation aux événements accrue .</li>",
                    "skills": "HTML, CSS pour le front, Joomla, PHP pour le back",
                }

            ]
        }
    },
});
