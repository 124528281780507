
import {defineComponent} from 'vue';
import $ from "jquery";
import commonOnLoad from "@/mixins/commonOnLoad";
import store from "@/store";
import GoogleAnalytics from "@/components/common/googleanalytics.vue";


export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ProfileView',
    components: {GoogleAnalytics},
    mixins: [commonOnLoad],
    mounted() {
        this.updateHeightContainer(this.$route.name);
    },
    computed: {
        withTracking() {
            return store.getters.withTracking;
        }
    },
    methods: {
    }
});
