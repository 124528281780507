
import {defineComponent} from 'vue';

import emitter from "@/services/mitt";


export default defineComponent({
    name: 'RaTing',
    props: ['data'],
    data() {
        return {
            displaySkill: false
        }
    },
    mounted() {
        // new Tooltip(document.body, {
        //     selector: "[data-bs-toggle='tooltip']",
        //     placement: "left"
        // })

        emitter.on('unfoldSkill',(skillName: string) => {
            if (this.data.name !== skillName) {
                this.displaySkill = false;
            }
        })

    },
    methods: {
        displayMoreInfos() {

            if (this.data.text) {
                this.displaySkill = !this.displaySkill;
                emitter.emit('unfoldSkill', this.data.name);
            }
        }
    }
});
