import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProfileView from "@/views/ProfileView.vue";
import SkillsView from "@/views/SkillsView.vue";
import PortFolioView from "@/views/PortFolioView.vue";
import LinksView from "@/views/LinksView.vue";
import CguView from "@/views/CguView.vue";
import page404View from "@/views/page404View.vue";
import ContactView from "@/views/ContactView.vue";
import OtherProjectsView from "@/views/OtherProjectsView.vue";
import TrainingView from "@/views/TrainingView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'profile',
        component: ProfileView,
        meta: {
            title: 'Vincent Fritz - Profil',
            metaTags: [
                {
                    name: 'description',
                    content: 'Profil professionnel de Vincent Fritz, ses compétences, son portfolio ainsi que son CV vidéo'
                },
                {
                    property: 'og:description',
                    content: 'Profil professionnel de Vincent Fritz, ses compétences, son portfolio ainsi que son CV vidéo'
                },
                {
                    property: 'og:title',
                    content: 'Profil'
                },
                {
                    property: 'og:url',
                    content: 'https://vincentfritz-dev.fr'
                },
                {
                    property: 'og:image',
                    content: 'https://vincentfritz-dev.fr/img/preview.5066f8fa.png'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:image:height',
                    content: '100'
                },
            ]
        }
    },
    {
        path: '/skills',
        name: 'skills',
        component: SkillsView,
        meta: {
            title: 'Vincent Fritz - Compétences',
            metaTags: [
                {
                    name: 'description',
                    content: 'Les compétences professionnelles de Vincent FRITZ'
                },
                {
                    property: 'og:description',
                    content: 'Les compétences professionnelles de Vincent FRITZ'
                },
                {
                    property: 'og:title',
                    content: 'Compétences'
                },
                {
                    property: 'og:url',
                    content: 'https://vincentfritz-dev.fr'
                },
                {
                    property: 'og:image',
                    content: 'https://vincentfritz-dev.fr/img/preview.5066f8fa.png'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:image:height',
                    content: '100'
                },
            ]
        }
    },
    {
        path: '/port-folio',
        name: 'port-folio',
        component: PortFolioView,
        meta: {
            title: 'Vincent Fritz - Expériences',
            metaTags: [
                {
                    name: 'description',
                    content: 'Les Expériences professionnelles de Vincent FRITZ'
                },
                {
                    property: 'og:description',
                    content: 'Les Expériences professionnelles de Vincent FRITZ'
                },
                {
                    property: 'og:title',
                    content: 'Expériences'
                },
                {
                    property: 'og:url',
                    content: 'https://vincentfritz-dev.fr'
                },
                {
                    property: 'og:image',
                    content: 'https://vincentfritz-dev.fr/img/preview.5066f8fa.png'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:image:height',
                    content: '100'
                },
            ]
        }
    },
    {
        path: '/other-projects',
        name: 'other-projects',
        component: OtherProjectsView,
        meta: {
            title: 'Vincent Fritz - Autres projets',
            metaTags: [
                {
                    name: 'description',
                    content: 'Les autres projets de Vincent FRITZ'
                },
                {
                    property: 'og:description',
                    content: 'Les autres projets de Vincent FRITZ'
                },
                {
                    property: 'og:title',
                    content: 'Autres projets'
                },
                {
                    property: 'og:url',
                    content: 'https://vincentfritz-dev.fr'
                },
                {
                    property: 'og:image',
                    content: 'https://vincentfritz-dev.fr/img/preview.5066f8fa.png'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:image:height',
                    content: '100'
                },
            ]
        }
    },
    {
        path: '/training',
        name: 'training',
        component: TrainingView,
        meta: {
            title: 'Vincent Fritz - Formation',
            metaTags: [
                {
                    name: 'description',
                    content: 'Les formations initiales et professionalisantes de Vincent FRITZ'
                },
                {
                    property: 'og:description',
                    content: 'Les formations initiales et professionalisantes de Vincent FRITZ'
                },
                {
                    property: 'og:title',
                    content: 'Formation'
                },
                {
                    property: 'og:url',
                    content: 'https://vincentfritz-dev.fr'
                },
                {
                    property: 'og:image',
                    content: 'https://vincentfritz-dev.fr/img/preview.5066f8fa.png'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:image:height',
                    content: '100'
                },
            ]
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactView,
        meta: {
            title: 'Vincent Fritz - Contact',
            metaTags: [
                {
                    name: 'description',
                    content: 'La page de contact de Vincent FRITZ'
                },
                {
                    property: 'og:description',
                    content: 'La page de contact de Vincent FRITZ'
                },
                {
                    property: 'og:title',
                    content: 'Contact'
                },
                {
                    property: 'og:url',
                    content: 'https://vincentfritz-dev.fr'
                },
                {
                    property: 'og:image',
                    content: 'https://vincentfritz-dev.fr/img/preview.5066f8fa.png'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:image:height',
                    content: '100'
                },
            ]
        }
    },
    {
        path: '/cgu',
        name: 'cgu',
        component: CguView
    },
    {path: '/:catchAll(.*)', redirect: '/404'},
    {
        path: '/404',
        name: 'page404',
        component: page404View
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

    // document.title = `${to.meta.title}`;
    //     next();

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach((tag:never) => document.head.appendChild(tag));

    next();
});

export default router
