
import {defineComponent} from 'vue';
import $ from "jquery";
import commonOnLoad from "@/mixins/commonOnLoad";
import store from "@/store";
import {Tooltip} from 'bootstrap';

import GoogleAnalytics from "@/components/common/googleanalytics.vue";

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ContactView',
    components: {GoogleAnalytics},
    mixins: [commonOnLoad],
    mounted() {
        this.updateHeightContainer(this.$route.name);

        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip-clipboard']",
            placement: "bottom"
        })
    },
    computed: {
        withTracking() {
            return store.getters.withTracking;
        }
    },
    methods: {
        async copyURL(mytext: string) {
            try {

                this.furtiveCopyTag = mytext;
                await navigator.clipboard.writeText(mytext);

                setTimeout(() => {
                    this.furtiveCopyTag = '';
                }, 1000)

            } catch ($e) {
                alert('Impossible de copier l\'adresse');
            }
        }
    },
    data() {
        return {
            furtiveCopyTag: '',
            links: [
                {
                    'title': 'linkedin',
                    'url': 'https://www.linkedin.com/in/vincent-fritz-69a588a8',
                    'classIcon': 'bi-linkedin',
                    'color': '#0A66C2'
                },
                {
                    'title': 'github',
                    'url': 'https://github.com/fvince25',
                    'classIcon': 'bi-github',
                    'color': '#24292F'
                },
                {
                    'title': 'mail',
                    'url': 'vincent.fritz77@gmail.com',
                    'classIcon': 'bi-envelope',
                    'isMail': true,
                    'color': '#FC6800'
                },
            ]
        }
    }
});
