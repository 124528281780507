import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'jquery'
import mitt from "mitt";

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const emitter = mitt();
const app = createApp(App);
app.config.globalProperties.emitter = emitter;


app.component('font-awesome-icon', FontAwesomeIcon);
app.use(store);
app.use(router);


app.mount('#app');
