import { createStore } from 'vuex'

export default createStore({
    state: {
        cookiesAnswered: false,
        displayFooter: false,
        tracking: false
    },
    getters: {

        withTracking(state) {
            return true;
            // return state.tracking
        },
        /**
         *
         */
        areCookiesAnswered(state) {
            return state.cookiesAnswered;
        },
        /**
         *
         */
        getDisplayFooter(state) {
            return state.displayFooter;
        },
    },
    mutations: {
        /**
         *
         * @param state
         * @param track
         */
        setTracking(state, track: boolean) {
            state.tracking = track;
        },
        /**
         *
         * @param state
         * @param cookiesAnswered
         */
        setCookiesAnswered(state, cookiesAnswered: boolean) {
            state.cookiesAnswered = cookiesAnswered;
        },

        /**
         *
         * @param state
         * @param displayFooter
         */
        setDisplayFooter(state, displayFooter: boolean) {
            state.displayFooter = displayFooter
        }
    },
    actions: {
    },
    modules: {
    }
})
