
import {defineComponent} from 'vue';
import store from '@/store'
import emitter from "@/services/mitt";

export default defineComponent({
    name: 'Footer',
    props: {
        msg: String,
    },
    computed : {
        displayFooter() {
            return store.getters.getDisplayFooter;
        }
    },
    methods : {
        notAnswerCookies() {
            store.commit('setCookiesAnswered', false);
            emitter.emit('reSubmitCookies');
        }
    }
});
