import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container-md mt-5 mb-5 pl-5 pr-5" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CooKies = _resolveComponent("CooKies")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavBar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, { name: "slide" }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }),
      (!_ctx.cookiesAlreadyAccepted)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_Transition, { name: "fadeCookies" }, {
              default: _withCtx(() => [
                (!_ctx.cookiesAnswered)
                  ? (_openBlock(), _createBlock(_component_CooKies, { key: 0 }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Footer)
  ], 64))
}