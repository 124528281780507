import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
      src: "https://www.googletagmanager.com/gtag/js?id=G-6B3SDBRR93",
      async: ""
    })),
    (_openBlock(), _createBlock(_resolveDynamicComponent('script'), { async: "" }, {
      default: _withCtx(() => [
        _createTextVNode(" window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-6B3SDBRR93'); ")
      ]),
      _: 1
    }))
  ], 64))
}