import $ from "jquery";
import router from "@/router";
import store from '@/store'
import { event } from 'vue-gtag'

export default {
    methods: {

        trackRoute(route: any) {
            event(route, {method: 'Google'})
        },
        /**
         *
         */
        updateHeightContainer(route: any) {
            store.commit('setDisplayFooter', false)
            setTimeout(()=> {
                let height = $(".articleContainer").height();
                if (!height) {
                    height = 0;
                }
                $(".container-md").css('height', (height + (route === "skills" || route === "page404" ? 300 : 0)) + 'px');
                store.commit('setDisplayFooter', true)

            },800)
        }}

}