
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'GoogleAnalytics',
    props: {
        msg: String,
    },
    methods: {}
});
