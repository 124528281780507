
import {defineComponent} from 'vue';
import store from '@/store'

import $ from "jquery";

export default defineComponent({
    name: 'CooKies',
    props: {
        msg: String,
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        acceptCookies() {

            store.commit('setCookiesAnswered', true);
            localStorage.setItem('cookies', 'accepted');
            const GoogleAnalytics = process.env.VUE_APP_GOOGLE_ANALYTICS;
            store.commit('setTracking', GoogleAnalytics === 'yes');

        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        declineCookies() {

            store.commit('setCookiesAnswered', true);
            localStorage.setItem('cookies', 'refused');
            store.commit('setTracking', false)
        }
    }
});
