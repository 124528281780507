
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'jquery/dist/jquery.slim.js'
import 'popper.js/dist/popper.js'
import 'bootstrap/dist/js/bootstrap.js'
import store from '@/store'


import NavBar from "@/components/NavBar.vue";
import CooKies from "@/components/common/cookies.vue";
import {defineComponent} from 'vue';
import Footer from "@/components/Footer.vue";
import $ from "jquery";
import emitter from "@/services/mitt";

export default defineComponent({
    name: 'App',
    components: {
        Footer,
        CooKies,
        NavBar
    },

    computed: {
        cookiesAnswered() {
            return store.getters.areCookiesAnswered;
        }
    },
    methods: {},
    data() {
        return {
            cookiesAlreadyAccepted : false
        }
    },
    created() {
        const cookieConsent = localStorage.getItem('cookies') === 'accepted';
        const withTracking = process.env.VUE_APP_GOOGLE_ANALYTICS === 'yes' && cookieConsent
        store.commit('setTracking', withTracking);
    },
    mounted() {

        const cookies = localStorage.getItem('cookies');

        if (cookies === "accepted") {
            store.commit('setCookiesAnswered', true);
        }

        if (cookies === "refused") {
            store.commit('setCookiesAnswered', true);
        }

        this.cookiesAlreadyAccepted = (cookies === 'accepted' || cookies === 'refused');
        emitter.on('reSubmitCookies', () => {
            this.cookiesAlreadyAccepted = false;
            console.log(this.cookiesAnswered);
        })
    }
})
