
import {defineComponent} from 'vue';
import $ from "jquery";

export default defineComponent({
    name: 'NavBar',
    props: {
        msg: String,
    },
    created() {
        $(document).ready(() => {
            let $navlinks = $('.nav-link').not('.dropdown-toggle');
            // console.log($navlinks)
            $navlinks.click((e) => {
                $(e.target).closest('.navbar').find('.navbar-collapse').collapse('toggle')
            })
        })
    }
});
