
/* add fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* add some free styles */
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'


library.add(faTwitter, faUserSecret);

import {defineComponent} from 'vue';
import $ from "jquery";
import store from '@/store'
import commonOnLoad from "@/mixins/commonOnLoad";
import GoogleAnalytics from "@/components/common/googleanalytics.vue";

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'cgu',
    components: {
        GoogleAnalytics
    },
    mixins: [commonOnLoad],
    metaInfo: {
        title: 'cgu'
    },
    mounted() {
        this.updateHeightContainer(this.$route.name);
    },
    computed: {
        withTracking() {
            return store.getters.withTracking;
        }
    },
    methods: {
    }
});
