
import {defineComponent} from 'vue';
import $ from "jquery";
import commonOnLoad from "@/mixins/commonOnLoad";
import store from "@/store";
import GoogleAnalytics from "@/components/common/googleanalytics.vue";


export default defineComponent({
    name: 'PortFolioView',
    components: {GoogleAnalytics},
    mixins: [commonOnLoad],
    methods: {},
    computed: {
        withTracking() {
            return store.getters.withTracking
        }
    },
    mounted() {
        this.updateHeightContainer(this.$route.name);
    },
    data() {
        return {
            experiences: [
                {
                    "id": "smp",
                    "title": "Développeur Web FullStack Symfony / Angular / Ionic - Mission Freelance",
                    "client": "Ministère de l'intérieur - ANTS / France Titre ( en prestation pour Soprasteria)",
                    "logo": "minint.svg",
                    "start": "mars 2023",
                    "end": "septembre 2024",
                    "content":
                        "<li><b>SIMPLIMMAT</b> est une application mobile rattachée à l’ANTS (auj. France Titres) permettant de céder et immatriculer son véhicule.</li>" +
                        "<li>Tierce maintenance applicative sur l'application mobile hybride <b>SIMPLIMMAT</b> ainsi que le back-office</li>" +
                        "<li>" +
                        "   <ul>" +
                        "       <li><b>Application mobile</b> : Cession / Immatriculation d’un vendeur à un acheteur.</li>" +
                        "       <li><b>Back Office :</b> Gestion des dossiers, interface de mise en maintenance, gestion des notifications, supervision, gestion users (app / BO)</li>" +
                        "       <li><b>Appels services tiers :</b> SIV (Système d'immatriculation des véhicules), utac (contrôle technique), France Connect, Gestionnaire de SMS , Paiement en ligne</li>" +
                        "   </ul>" +
                        "</li>",

                    "skills": "<b>Backend :</b> Symfony 6, PostGreSQL, PHP8, PHP Unit, Docker, Kubernetes, Mercure (web-socket) <b>Frontend</b> : Ionic, Angular, JEST, javascrit, JQuery, Firebase (push Notif), Android studio, Xcode <b>Qualité</b> : Tests unitaires (JEST / PHP Unit), Sonarlint, SonarQube",
                    "recommendations": [
                        {
                            "name": "Axel Barbosa",
                            "role": "Lead Tech - Simplimmat",
                            "text": "Au cours de sa mission en tant que développeur FullStack, Vincent a su rapidement monter en compétences sur le plan fonctionnel sur un sujet qui était nouveau pour lui.\n Il a également pu, sur le plan technique, apporter ses connaissances à l'aide de ses expériences passées sur le développement mobile et Symfony 6.\n" + "Sa volonté de bien faire et sa détermination ont permis au client d'être pleinement satisfaits des évolutions et corrections d'anomalies réalisées."
                        },
                        {
                            "name": "Charles Servel de Cosmi",
                            "role": "Directeur de Projets - Simplimmat",
                            "text": "Vincent est un développeur sérieux, tenace et solide techniquement. Il a été un contributeur important pour garantir la qualité de la production et pour apporter son soutien au reste de l'équipe. C'était très rassurant de l'avoir sur le projet"
                        }
                    ],
                    "sourceRecommandation" : "linkedin"
                },
                {
                    "id": "pdb",
                    "title": "Développeur Web FullStack Symfony / VueJS - Mission Freelance",
                    "client": "La Plateforme du bâtiment - Groupe Saint Gobain",
                    "logo": "pdb.png",
                    "start": "mars 2022",
                    "end": "décembre 2022",
                    "content":
                        "<li>La Plateforme du bâtiment est une enseigne de référence dans la vente de produits liés à la construction.</li>" +
                        "<li>Développements et maintenance from scratch sur 4 applications :</li>" +
                        "<li>" +
                        "   <ul>" +
                        "       <li>Application (PWA / PDA) de gestion de la sécurité dans les dépôts + interface d’administration pour les directeurs de dépôts.</li>" +
                        "       <li>Interface de panneau de contrôles de l’état de fonctionnement des différentes applis et ressources (serveurs / bases de données)</li>" +
                        "       <li>Application de saisie de réapprovisionnement en produits des dépôts</li>" +
                        "   </ul>" +
                        "</li>",
                    "skills": "<b>Backend :</b> Symfony 3 – 6, MySQL, MariaDB, PostMan, SSO Oauth2 <b>Front :</b> VueJS 3, JQuery, IndexedDB, PWA, API Rest",
                    "recommendations": [
                        {
                            "name": "Guillaume Hubert",
                            "role": "Directeur Informatique de La Plateforme du Bâtiment - SGDB",
                            "text": "Vincent a travaillé dans mes équipes pendant 10 mois en tant que développeur sur plusieurs applications.\n" +
                                "Il est très investi et à a cœur de satisfaire ses clients internes avec les applications qu'il développe. C'est quelqu'un d'ouvert, de curieux et de persévérant. Il a su prendre en charge rapidement les différents projets qui lui ont été confiés."
                        },
                        {
                            "name": "Rémi Masson",
                            "role": "Responsable de Domaine Digital at La Plateforme du Bâtiment - SGDB France",
                            "text": "J'ai pu encadrer Vincent sur plusieurs projets comme développeur PHP & VueJS. Son implication sur ses projets, sa persévérance à résoudre les problématiques rencontrées, ainsi que sa grande curiosité lui ont permis de mener ses différentes missions à terme et d'être réellement reconnu par les équipes métier pour sont travail."
                        },
                        {
                            "name": "François Fernet",
                            "role": "Responsable Service Approvisionnement chez La Plateforme du Bâtiment - SGDB France",
                            "text": "Vincent a pu accompagner mon équipe sur l'amélioration et la digitalisation d'un process métier particulièrement irritant. J'ai spécialement apprécié sa volonté et sa capacité à comprendre nos besoins opérationnels afin de les traduire au mieux dans l'outil développé, tout en gardant une vision \"client\", primordiale dans la construction de ce projet !"
                        },
                        {
                            "name": "Nicolas CHOISY",
                            "role": "Ingénieur développement",
                            "text": "Vincent est très impliqué dans son travail et a réalisé sa mission sans aucun problème.\n" +
                                "Il a été efficace dans ses différentes réalisations de développement.\n" +
                                "Il a une vrai attirance pour le frontend.\n" +
                                "Je recommande sans problème."
                        },

                    ],
                    "sourceRecommandation" : "linkedin"
                },
                {
                    "id": "opco",
                    "title": "Développeur Web FullStack PHP - Mission Freelance",
                    "client": "OPCO Mobilités",
                    "logo": "opco.png",
                    "start": "mai 2021",
                    "end": "décembre 2021",
                    "content":
                        "<li>Opco Mobilités est un opérateur de compétences spécialisé dans les transports</li>" +
                        "<li><ul><li>Développements et maintenance sur l'application Colsa, qui gère entre autre la collecte " +
                        "de la taxe d'apprentissage et la cotisation pour la formation professionnelle continue.</ul></li></li>",
                    "skills": "PHP (framework fuelPHP, proche de Symfony), MySQL",
                    "recommendations":
                        [{
                        "name": "Florent COUDERC",
                        "role": "Chef de projet",
                        "text": "Vincent a su s'adapter à l’environnement très spécifique des OPCO, et s’intégrer " +
                            "rapidement à l’équipe projet déjà en place. Cela lui a permis d'être pleinement autonome " +
                            "pour les différents développements qui lui ont été confiés. Vincent s'est investi avec un " +
                            "grand professionnalisme dans son travail et ça a été un plaisir de collaborer durant sa " +
                            "mission parmi nous."
                    }]
                },
                {
                    "id": "vallourec",
                    "title": "Développeur Web Fullstack Symfony - Mission Freelance",
                    "client": "Vallourec",
                    "logo": "vallourec.jpg",
                    "start": "février 2021",
                    "end": "mars 2021",
                    "content":
                        "<li>Maintenance sur la marketplace de Vallourec, application web en triple Stack Angular 9 (Front E-Commerce) NodeJS (Middleware) Symfony (MVC / API Platform).</li>",
                    "skills": "PHP 7, Symfony 4, NodeJS, Angular 9, Git, Gitlab",
                    "recommendation": ""
                },
                {
                    "id": "akto",
                    "title": "Développeur Web Fullstack - Mission Freelance",
                    "client": "Akto",
                    "logo": "akto.png",
                    "start": "octobre 2019",
                    "end": "décembre 2020",
                    "content":
                        "<li>AKTO est un opérateur de compétences des services à forte intensité de main d'œuvre. " +
                        "Il agit pour le développement de l'alternance, l'emploi durable et la construction de parcours professionnels.</li>" +
                        "<li>Développement et maintenance (PHP, MySQL, Angular 7) sur les différentes applications WEB de l'entreprise (Meetop, le Fil)," +
                        " Développement d'une nouvelle version du site \"du Fil\" en Angular et application mobile Android/IOS en technologie Ionic 5</li>",
                    "skills": "Angular, Ionic, HTML, Javascript, JQuery, PHP, Websockets PHP, Mysql, CSS, GIT",
                    "recommendations": [
                        {
                            "name": "Nicolas Memin",
                            "role": "Responsable de la Stratégie Digitale et des Systèmes d'Information",
                            "text": "Vincent a intégré l’équipe très rapidement. Il a compris nos problématiques et a répondu à toutes nos attentes.\n" +
                                "Excellent niveau de développement (Angular)"
                        },
                        {
                            "name": "Kevin Chaussé",
                            "role": "Chef de projet junior chez AKTO",
                            "text": "Sollicité pour travailler sur un projet PHP/MySQL et un projet Angular/Ionic, Vincent a su être compétent, soucieux de la qualité du travail fourni et désireux d'évoluer et apprendre sur les technologies à utiliser. \n" +
                                "Je ne peux que le recommander."
                        },
                        {
                            "name": "Mucahid CAKMAK",
                            "role": "Développeur Web Fullstack",
                            "text": "J'ai eu la chance de collaborer avec Vincent au sein du même groupe.\n" +
                                "Vincent a su s'adapter très rapidement aux différentes technologies du client, \n" +
                                "il fait preuve d’un réel professionnalisme et d’une rigueur à toute épreuve.\n" +
                                "\n" +
                                "Je suis certain que sa détermination, son envie d'apprendre toujours plus et ses nombreuses autres qualités lui permettront de belles réussites pour les missions à venir."
                        },
                        {
                            "name": "Wael Letaief",
                            "role": "Team Coordinator / Data Manager",
                            "text": "Ce fut un plaisir de collaborer avec Vincent, quelqu'un de sérieux et très professionnel, a participé pendant sa mission au développement des applications internes qui ont satisfait les besoins des équipes aux sein de la direction d'activité territorial. "
                        },
                    ],
                    "sourceRecommandation" : "linkedin"

                },
                {
                    "id": "hallcouture",
                    "title": "Développeur/Consultant Wordpress - Mission Freelance",
                    "client": "Hall Couture",
                    "logo": "hallCouture.png",
                    "start": "août 2019",
                    "end": "",
                    "content":
                        "<li>La société Hall Couture est un atelier de coworking spécialisé dans l'habillement.</li>" +
                        "<li>Le back Office du site est géré par Wordpress.</li>" +
                        "<li>" +
                        "   <ul>" +
                        "       <li>Version multilingue.</li>" +
                        "       <li>Gestion du règlement général sur la protection des données (RGPD)</li>" +
                        "   </ul>" +
                        "</li>" +
                        "<li>Travaux d'audit</li>" +
                        "<li>" +
                        "   <ul>" +
                        "       <li>Audit de sécurité ( Migration HTTPS)</li>" +
                        "       <li>Audit Search Engine Optimisation (SEO)</li>" +
                        "       <li>Audit de vitesse de chargement</li>" +
                        "   </ul>" +
                        "</li>",
                    "skills": "PHP, Wordpress, HTML, CSS, Javascript, MySQL, SEO",
                    "recommendation": ""
                },
                {
                    "id": "panameeyewear",
                    "title": "Développeur Fullstack PHP - Mission Freelance",
                    "client": "Paname Eye Weare",
                    "logo": "panameeyewear.png",
                    "start": "juillet 2019",
                    "end": "",
                    "content":
                        "<li>Paname EyeWear est une société de vente en gros de lunettes de vue et solaires.</li>" +
                        "<li>Mise en place de nouvelle fonctionnalités sur le site internet de la société:" +
                        "   <ul>" +
                        "       <li>Ajout d'un système d'ajout groupé de nouveaux produits par fichier CSV.</li>" +
                        "       <li>Contrôle de l'intégrité des données chargées</li>" +
                        "       <li>Appariement automatique des images des produits Refonte du design de la home</li>" +
                        "   </ul>" +
                        "</li>" +
                        "<li>Refonte du design de la home</li>" +
                        "   <ul>" +
                        "       <li>Possibilité d'ajouter un fil d’actualité.</li>" +
                        "       <li>Texte Personnalisé central</li>" +
                        "   </ul>",
                    "skills": "PHP, HTML, CSS, Javascript, MySQL",
                    "recommendation": ""
                },
                {
                    "id": "mainframe",
                    "title": "Expériences Mainframe - CDI",
                    "client": "Divers clients",
                    "logo": "multiMainframe.png",
                    "start": "2007",
                    "end": "2019",
                    "content":
                        "<li>J'ai travaillé près de 12 ans dans le domaine des mainframes (Grands systèmes)</li>" +
                        "<li>Cette carrière m'a permis de me forger une méthodologie et une rigueur en termes de gestion de projet informatique. Je me sens donc aujourd'hui très à l'aise pour aborder des projets Web complexes, incorporant un fonctionnel sophistiqué. </li>",
                    "skills": "Cobol, Pacbase, Base de données DB2",
                    "recommendation": ""
                },

            ]
        }
    },
});
