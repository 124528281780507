
import {defineComponent} from 'vue';
import $ from "jquery";
import commonOnLoad from "@/mixins/commonOnLoad";
import store from "@/store";
import GoogleAnalytics from "@/components/common/googleanalytics.vue";


export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'TrainingView',
    components: {GoogleAnalytics},
    mixins: [commonOnLoad],
    methods: {},
    computed: {
        withTracking() {
            return store.getters.withTracking;
        }
    },
    mounted() {
        this.updateHeightContainer(this.$route.name);
    },
    data() {
        return {
            initialTrainings: [
                {
                    "id": "ulp",
                    "title": "Master 2 - Micro Nano Electronique",
                    "organism": "Université Louis pasteur, Strasbourg",
                    "logo": "ulp.png",
                    "start": "2005",
                    "content":
                        "",
                    "skills": "Technologies des semis concucteur, technologie CMOS, Micro-contrôlleurs et micro-processeurs, langage C et C++",
                }
                ],
            proTrainings: [
                {
                    "id": "symfony",
                    "title": "Formation en ligne Symfony 5",
                    "organism": "Web Develop Me.",
                    "logo": "lior.png",
                    "start": "février 2021",
                    "content":
                        "",
                    "skills": "Symfony 5, doctrine, security, validators, subscribers, etc.",
                },
                {
                    "id": "cifap",
                    "title": "Développeur WEB Front, gestion du Web / multimedia et webmaster",
                    "organism": "CIFAP",
                    "logo": "cifap.png",
                    "start": "avril 2018",
                    "end": "septembre 2018",
                    "content":
                        "",
                    "skills": "Front : HTML, CSS/SCSS, Bootstrap, Javascript, Jquery. Back : PHP, NodeJS, MYSQL, MongoDB, WordPress. Design/UX : Lois d’ergonomie, Photoshop, Axure",
                    "recommendations": [
                        {
                            "name": "Masis Gulmez",
                            "role": "Enseignant - Formateur",
                            "text": "Vincent est très sérieux, en plus d'être un excellent développeur, il est passionné par ce qu'il fait. Je recommande ses compétences, notamment pour PHP/Symphony, MySQL, JavaScript et Angular. Pour son projet de fin d'étude, Vincent a développé une application web avec NodeJS, en s'appuyant sur l'API de Spotify. Permettant aux utilisateurs connectés d'interagir avec une playlist musicale."
                        }
                    ]

                },
                {
                    "id": "plb",
                    "title": "Javascript",
                    "organism": "PLB Consultant",
                    "logo": "plb.png",
                    "start": "Aôut 2016",
                    "content":
                        "",
                    "skills" : 'Cours intensif sur javascript et JQuery'
                }
            ]
        }
    },
});
